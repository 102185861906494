




.CloseButton {
  margin-left: auto; /* added to justify to right */
}

.button-small.close-button-r{
  border-left: var(--border-internal);
  border-right: none;
}






