@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,700&display=swap');

/*
* Overide any defaults
*/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body,
#root,
.App{
  height: 100%;
  background-color: var(--color-tertiary);
  position: relative;
}

body {


    
    /* Debug border */
    --debug-border: none; /*2px solid #ffa825; */

    /* Colours */
    --color-primary: #003f56;;
    --color-primary-accent: #003f56; 
    --color-primary-hover: rgb(0, 50, 69);;
    --color-hyperlink: #2D58C7; /*used for hyper links*/
    --color-ripple: rgba(0, 63, 86, 0.7);

    --color-secondary: #FFFFCB; /*used for dark button text */
    --color-secondary-accent: #DFE7BC;
    
    --color-tertiary: rgb(215, 213, 213);
    --color-teritary-accent: #4B4B4B;
    
    --color-neutral: #F6F6F6; /* used for modal backgrond */
    --color-neutral-accent: #FFFFFF;  /* used for panels */

    /* Menu colors */
    --color-menu-buttons: var(--color-primary); /*LNER Oxblood */
    --color-menu-buttons-select:var(--color-teritary-accent); /* LNER  prime red */
    --color-menu-buttons-select-background: #EAEEF9;

    /* Text colors */
    --color-text-heading: var(--color-primary);
    --color-text-subheading: var(--color-primary);
    --text-transform-heading: none;

    /* Punctuality RAG colours */
    --color-rag-red: #ff2530;
    --color-rag-amber: #ffa825;
    --color-rag-green: #009b83;
    --color-rag-neutral: #74CEB7;

    /* stops */
    --color-stops-line: var(--color-primary);
    --color-stops-end-dot: var(--color-primary);
    --color-stops-end-dot-center:var(--color-secondary);

    /* Font face */
    --font-family-primary: 'Manrope', sans-serif;
    --font-family-secondary: 'Roboto Mono', 'Courier New', monospace;
    --font-button: bold 16px var(--font-family-secondary), sans-serif;

    --box-shadow-top: 0 -4px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.18);

    /* sizes */
    --size-large: 20px;
    --size-medium: 15px;
    --size-small: 10px;

    /* Overlays */
    --overlay-border: #4B4B4B 0px solid;
    --overlay-border-radius: 3px;
    --overlay-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.18);
    --border-internal: #999999 1px solid;

    --map-border: 6px var(--color-primary) solid
}

/* Avanti Specific Stuff */
@font-face {
  font-family: Averta-Semibold;
  src: url('themes/avanti/fonts/Averta-Semibold.ttf') format('truetype');
}

@font-face {
    font-family: Averta;
    src: url('themes/avanti/fonts/Averta-Regular.ttf') format('truetype');
}


/* TfW fonts */
@font-face {
    font-family: wales-sans-body-medium;
    src: url('themes/tfw/fonts/WALES-SANS-BODY-MEDIUM.OTF') format('truetype');
  }
  
  @font-face {
      font-family: wales-sans-body-regular;
      src: url('themes/tfw/fonts/WALES-SANS-BODY-REGULAR.OTF') format('truetype');
  }


/* Trainline fonts */
@font-face {
    font-family: tl-circular-bold;
    src: url('themes/trainline/fonts/TLCircular-Bold.otf') format('truetype');
  }
  

  @font-face {
      font-family: tl-circular-book;
      src: url('themes/trainline/fonts/TLCircular-Book.otf') format('truetype');
  }



/**
* Font styles
*/

h1{
    color: var(--color-text-heading);
    font-family: var(--font-family-primary);
    font-size: 24px;
    font-weight: 800;
    border: var(--debug-border);
    text-transform: var(--text-transform-heading);
    padding-top: 10px;
}

h2{
    color:  var(--color-text-heading);
    font-family: var(--font-family-primary);
    font-size: 20px;
    font-weight: 900;
}

h3{
    color:  var(--color-text-subheading);
    font-family: var(--font-family-primary);
    font-size: 18px;
    font-weight: 900;
}


p{
    color: var(--color-text-paragraph);
    font-family: var(--font-family-primary);
    font-size: 16px;
    font-weight: 400px;
    line-height: 24px;
    padding-bottom: 30px;
}

/* label for autocomplete inputs */
.label{
    border: var(--debug-border);
    font-family: var(--font-family-secondary);
    font-size: 12px;
    color: #999999;;
    padding-block: 4px;
}

/* Menu buttons */

.button-small{
    position: relative;
    border-right: var(--border-internal);
    height: 56px;
    width: 56px;
}

.button-small svg{
    position: absolute;
    color: var(--color-menu-buttons);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    height: 17px;
    stroke: var(--color-menu-buttons);
    fill: var(--color-menu-buttons);
    border: var(--debug-border)
}

.button-small:hover svg{
    /* background-color: var(--color-tertiary); */
    stroke: var(--color-menu-buttons-select);
    fill: var(--color-menu-buttons-select);

}

.button-small.selected{
    /* background-color: var(--color-tertiary);*/
    border-bottom: 4px solid var(--color-menu-buttons-select);
    
} 

.button-small.selected svg{
    color: var(--color-menu-buttons-select);
    stroke: var(--color-menu-buttons-select);
    fill: var(--color-menu-buttons-select);
}

/**
* Autocomplete and form styling
**/
.autocomplete{
    font-size: 16px;
    color: var(--color-text-paragraph) !important;
    font-family: var(--font-family-primary) !important;
    
}

.autocomplete__control{
    border: solid var(--color-primary) 1.5px;
    height: 60px; 
    border-radius: var(--overlay-border-radius) !important;
}

.autocomplete__control--is-focused{
    border: solid var(--color-primary) 2px !important;
    box-shadow: 0 0 0 1px solid var(--color-primary) !important;
}

.autocomplete__control--menu-is-open{
    border: solid var(--color-primary) 2px !important;
}
/* .autocomplete__option{
    height: 50px;
} */


.autocomplete__option--is-focused{
    background-color: var(--color-menu-buttons-select-background)  !important;
 
}
.autocomplete__value-container{
    padding: 5px
}

.autocomplete__option--is-selected{
    background-color: #EAEEF9 !important;
    color: var(--color-primary) !important;
}

/* This sets the maximum height of the menu */
.autocomplete__menu-list{
    height: 160px;
}

/*
* layout and utiltity classes
*/

.container{
    padding: 20px var(--size-large);
    height: 100%;
    overflow-y: auto;
    border: var(--debug-border)
}


/* class for centering */
.center{
    position: absolute;
    border: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.text-center{
    text-align: center;
}

.padding-large{
    padding-block: var(--size-large) var(--size-large);
}

.padding-medium{
    padding-block: var(--size-medium) var(--size-medium);
}

.padding-small{
    padding-block: var(--size-small) var(--size-small);
}

.color-primary{
    color: var(--colar-primary)
}

/**
* Button styles
*/
button{
    width: 100%;
    height: 64px;
    border-radius: var(--overlay-border-radius);
    border: 2.5px solid var(--color-primary);
    font: var(--font-button); 
    background-color: transparent;
}

.button-light{
    border-color: white; 
    color: var(--color-primary);
    background-color: white;
}
.button-light:hover{
    background-color: var(--color-primary);
    color: white;
}

.button-light:disabled{
    border: none;
    color: #003F56;
    background-color: #809FAB;
    opacity: 0.4;
}

.button-dark{
    background-color: var(--color-primary); 
    color: var(--color-secondary);
  }
  
  .button-dark:hover{
    /* background-color: brightness(var(--color-primary), 110%); */
    background-color: var(--color-primary-hover);
    /* color: var(--color-primary); */
    border: solid 2px var(--color-primary); 
    box-shadow:  4px 4px rgba(184, 181, 181, 0.25)
  }
  
