


/*
Utility classes 
*/

.Train{
    overflow: hidden;
    position: relative;
    height: 100%;
}


.Train .body{
    /* padding: 30px; This should probably be a variable */
    height: calc(100% - 180px);
    overflow-y: auto;
    border-bottom: var(--border-internal);
}

.Train .form-container{
    padding-block: 28px;
}

.Train .footer{
    position: relative;
    height: 180px;
}
 
    



