.TrainIcon {
    color: var(--bg-primary);
    position: relative;
    font-size: 1px;
    line-height: 1px;
    cursor: pointer;
}


.TrainIcon .green {
    fill: var(--color-rag-green);
}

.TrainIcon .amber {
    fill: var(--color-rag-amber);
}

.TrainIcon .red {
    fill: var(--color-rag-red);
}

.TrainIcon .label {
    width: 100%;
    color: var(--color-primary);
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    line-height: 1px;
    font-weight: 900;
    font-size: 16px;
}




