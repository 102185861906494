.EtaContainer{
    position: relative;
    height: 100%;
    width: 100%;
}

.EtaContainer .section-text{
    position: relative;
    height: 25%;
    border: var(--debug-border);
    background-color: none;
    z-index: 10;
}

.EtaContainer .text-wrapper{
    padding: 20px 15% 0 15%;;
}

.EtaContainer .logo-wrapper{
    padding: 10px 20px; 
    height: 40px;
    border: var(--debug-border);
}

.EtaContainer .title{
    font-size: 40px;
    width: 100%;
    color: var(--color-primary);
    margin: auto;
    padding-block: 15px 0 ;
    text-align: center;

}

.EtaContainer .stations{
    font-size: 20px;
    font-weight: 400;
    color: #440F11; /*LNER Oxblood */
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.EtaContainer .section-map{
    position: relative;
    height: 50%;
    z-index: 10;
    border: var(--debug-border);
}
.EtaContainer .section-map iframe{
    z-index: inherit;
}


.EtaContainer .eta-container{
    width: 70%;
    height: 100%;
    margin: auto;
}

.EtaContainer iframe{
    border: none;
    border-radius: var(--overlay-border-radius);
}

.EtaContainer .arrival-station-text{
    color: var(--color-neutral);
    font-size: 20px;
    font-weight: 700;
    border: var(--debug-border);
    margin: auto;
    padding: 2px 15% 5px 15%;
    text-align: center;
}

.EtaContainer .arrival-time-wrapper{
    padding: 4px;
}


.EtaContainer .arrival-time{
    color: var(--color-neutral);
    font-size: 23px;
    text-transform: uppercase;
    background-color: var(--color-primary-lighter);
    width: fit-content;
    height: fit-content;
    padding: 16px;
    border-radius: 6px;
    margin: auto;
}

/* Bottom background */

.EtaContainer .top-background{
    position: absolute;
    top: 0;
    bottom: 50%;
    width: 100%;
    background-color: var(--color-neutral);  
    z-index: 0;
    border: var(--debug-border);
}

.EtaContainer .bottom-background{
    position: absolute;
    bottom: 0;
    top: 50%;
    width: 100%;
    background-color: var(--color-primary);
    z-index: 0;
}

.EtaContainer .lner-line-wrapper{
    position: absolute; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 2.2%;
    z-index: 10
}

/* .EtaContainer .grid{
    position: absolute;
    z-index: 10;
    height: 100%;
    width: 100%;
    background-image: radial-gradient(#DEE7BC 2px, transparent 0);
    background-size: 120px 120px;
    background-position: -19px -19px;
    z-index: 1;
} */


@media only screen and (max-width: 500px) {
    .EtaContainer .title {
      font-size: 24px;
      text-align: left;
    }
    .EtaContainer .stations {
        font-size: 19px;
        text-align: left;  
      }
    .EtaContainer .arrival-station-text{
        font-size: 15px;
        text-align: left;
    }
  
  }