.TfwNoTrains{
  position: absolute;
  border: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.TfwNoTrains img{
  height: 124px;
}
