
.TrainSummary{
    border: var(--border-internal);
    border-radius: var(--overlay-border-radius);
    background-color: white;
    padding: 16px;
}

.TrainSummary.select-enabled{
    cursor: pointer;
}


.TrainSummary.select-enabled:hover {
    background-color: var(--color-tertiary);
    /* border-color: var(--color-primary); */
    box-sizing:border-box;
    box-shadow: 0 0 0 2px var(--color-primary) inset;
}

.TrainSummary .wrapper{
    width: 100%
}

.TrainSummary .toc-name{
    font-family: var(--font-family-primary);
    font-weight: 700;
    font-size: 14px;
    border: var(--debug-border);
    padding-bottom: 2px;
}

.TrainSummary h2 {
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 28px;
    color: var(--color-text-heading);
    border: var(--debug-border);
    padding-block: 7px;
}

.TrainSummary .display-time {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    font-family: var(--font-family-primary);
    padding-left: 5px;
    color: #4B4B4B;
}


.TrainSummary .train-delay{
    border: var(--debug-border);
    font-family: var(--font-family-primary);
    color: var(--color-teritary-accent);
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    
}

.TrainSummary .recentre-wrapper{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;  
}

.TrainSummary .headcode{
    font-size: 12px;
    font-weight: 700;
    color: #4B4B4B;
    font-family: var(--font-family-primary);
    height: fit-content;
}

.TrainSummary .share-button{
    height: 55px;
    color: var(--color-text-heading);
    border-color: var(--color-text-heading);
}

.TrainSummary .share-button:hover{
    background-color:   var(--color-tertiary);
}








