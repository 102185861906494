.TrainIcon {
    color: var(--bg-primary);
    position: relative;
    font-size: 1px;
    line-height: 1px;
    cursor: pointer;
}

.TrainIcon .green {
    fill: var(--color-rag-green);
}

.TrainIcon .amber {
    fill: var(--color-rag-amber);
}

.TrainIcon .red {
    fill: var(--color-rag-red);
}

