
.ModalNavigation{
    position: absolute;
    top: 10px;
    left: 10px;
    bottom: 20px;
    width: 392px;
    z-index: 10;
    border: var(--debug-border);
    pointer-events: none;
    border-radius: var(--overlay-border-radius);
    overflow: hidden;
}

.ModalNavigation.open{
  box-shadow:  var(--overlay-box-shadow);
}


.ModalNavigation .header{
  pointer-events: auto;
  height: 56px;
  background-color: var(--color-neutral);
  /* take the border stuff out into a utility class*/
  border-radius: var(--overlay-border-radius);
  box-shadow: var(--overlay-box-shadow);
  border: 1px solid #DADADA; 
  /* border: var(--overlay-border); */
}

.ModalNavigation .header.open{
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: var(--border-internal);
  box-shadow: none;
}


/* .ModalNavigation .logo-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 3px;
  border-right: var(--border-internal);
} */


.ModalNavigation .button-wrapper{
    pointer-events: auto;
    height: 56px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
   
}


.ModalNavigation .modal-body{
  pointer-events: auto;
  background-color: var(--color-neutral);
  width: 100%;
  height: calc(100% - 56px);
  border: var(--debug-border);
  overflow-y: auto;
}



/* @media only screen and (max-width: 600px) {
    .menu {
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
} */

/*mobile*/
@media only screen and (max-width: 500px) {
  .ModalNavigation {
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
  }

  .ModalNavigation.open {
    top: 30%;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: auto
  }

  .ModalNavigation.home.open {
    top: 0%;
  }

  .ModalNavigation.find_my_train.open {
    top: 10%;
  }

  .ModalNavigation.search.open {
    top: 10%;
  }

  .ModalNavigation.info.open {
    top: 10%;
  }


}