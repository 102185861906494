.TrainlineHomeWidget{
    position: relative;
    border: var(--debug-border);
    background-color: #F5F5F5;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 100;
    pointer-events: auto;
}

.TrainlineHomeWidget .logo-wrapper img{
    height: 60%;
}

.TrainlineHomeWidget .logo-wrapper{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 180px;
    padding-block: 50px 0px;
    border: none;
}



.TrainlineHomeWidget .button-container{
    position: relative;
    z-index: 10;
    overflow-x: hidden;
    height: calc(100% - 250px);
    padding: 50px 20px 0 20px;
}


.TrainlineHomeWidget .text-wrapper p{
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
    padding: 0;
}

.TrainlineHomeWidget .description{
    font-family: var(--font-family-secondary);
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    width: 100%;
    text-align: center;
    padding-block: 0 5px;
}

.TrainlineHomeWidget .button-wrapper{
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TrainlineHomeWidget .button-super-dark{
    background: black;
    border: black;
    color: white
}

.TrainlineHomeWidget .button-super-dark:hover{
    background-color: white;
    color: var(--color-primary);
}

.TrainlineHomeWidget .attribution{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: var(--debug-border);
    height: 50px;
}
.TrainlineHomeWidget .attribution p{
    color: #000000;
    font-size: 12px;
    padding: 0 7px 0 0;
    border: var(--debug-border);
    line-height: 0;
}




