.MapContainer {
    overflow: hidden;
    position: absolute;
    height: 100%;
    width: 100%;

    background-color: var(--bg-secondary);
    font-family: var(--primary-font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* border: 10px var(--color-primary) solid;  */
}

.MapContainer.border{
    border: var(--map-border);
}


.MapContainer .location-unavailable{
    position: fixed;
    width: 100%;
    height:  100%;
    font-family: var(--primary-font-family);
    background-color: var(--color-brand-primary);
    z-index:100000;
    opacity: 0.9;
}

.MapContainer .MainMenu{
    top: 12px;
    left: 12px;
}

.MapContainer .copyright-wrapper{
    position: absolute;
    bottom: 2px;
    left: 2px;
}

.MapContainer .copyright-wrapper h2{
    background-color: var(--color-neutral);
    font-size: 10px;
}








