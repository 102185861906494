.EtaSharing {
    width: 100%;
    height: 220px;
    border: var(--border-debug)
}


.EtaSharing h2{
    font-size: 14px;
}


.EtaSharing h4{
    font-size: 12px;
    font-family: 'Roboto Mono', monospace;
    padding: 5px 0 10px 0;
    color: #999999;
}

.EtaSharing .toast{
    font-size: 12px;
    font-family: 'Roboto Mono', monospace;
    padding: 20px 0 20px 0;
}
