.RippleLoader{
  position: absolute;
  border: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



$ripple-color: var(--color-ripple);


.RippleLoader .signalbox-ripple {
  color: #fff;
  width: 11px;
  height: 11px;
  background-color: 	rgba(255, 255, 203, 1);
  margin: 0 auto;
  border-radius:50% !important;
  -webkit-animation: signalbox-ripple .7s linear infinite;
  animation: signalbox-ripple .7s linear infinite; 
  z-index: 2; 
  border: none !important;
}

@-webkit-keyframes signalbox-ripple {
  0% {
    box-shadow: 0 0 0 0 $ripple-color, 0 0 0 10px $ripple-color, 0 0 0 30px $ripple-color, 0 0 0 60px $ripple-color}
  100% {
    box-shadow: 0 0 0 10px $ripple-color, 0 0 0 30px $ripple-color, 0 0 0 60px $ripple-color, 0 0 0 90px $ripple-color; } }

@keyframes signalbox-ripple {
  0% {
    box-shadow: 0 0 0 0 $ripple-color, 0 0 0 10px $ripple-color, 0 0 0 30px $ripple-color, 0 0 0 60px $ripple-color; }
  100% {
    box-shadow: 0 0 0 10px $ripple-color, 0 0 0 30px $ripple-color, 0 0 0 60px $ripple-color, 0 0 0 90px rgba(76, 76, 74, 0.1); } }