.AvantiKey{
    border: var(--debug-border);
    width: 100%;
    height: 100%;
    overflow-y: auto;

}


.AvantiKey .body{
    border: var(--debug-border);
    padding: 25px 30px 30px 30px;
}


.AvantiKey .row{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    height: 40px;
    width: 100%;
}

.AvantiKey .train-icon-div{
    position: relative;
    height: 100%;
    width: 30px;
}

.AvantiKey .train-icon{
    position: absolute;
    top: 50%;
    left: 50%;
}

.AvantiKey .train-icon-description{
    height: 1px;
    line-height: 1px;
    padding-left: 9px;
    margin: 0;
}

.AvantiKey .train-icon-description p{
    padding: 0;
    line-height: 0;
}

.AvantiKey .direction-description{
    padding-top: 30px;
}


