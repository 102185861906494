
.SearchResult{
    overflow: hidden;
    position: relative;
}

.SearchResult .header{
    position: relative;
    height: max-content;
    border: var(--debug-border);
    padding: 28px 30px 10px 30px;
    border-bottom: var(--border-internal);
}

.SearchResult .header .title{
    border: var(--debug-border);
    font-size: 18px;
    font-family: var(--primary-font-family);
    font-weight: bold;
    color: var(--color-primary);
}

.SearchResult .header .trains-found{
    border: var(--debug-border);
    padding-top: 11px;
    font-size: 16px;
    font-family: var(--secondary-font-family);
    font-weight: bold;
    color: var(--color-text-secondary);
}

.SearchResult  .header .summary-container{
    display: flex; /* or inline-flex */
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: space-between;
}

.SearchResult .header .delay{
    border: var(--debug-border);
    padding: 4px 0;
    font-size: 16px;
    font-family: var(--secondary-font-family);
    font-weight: bold;
    color: var(--color-text-secondary);
}

.SearchResult .header .view-trains{
    border: var(--debug-border);
    font-family: var(--primary-font-family);
    padding: 0;
    font-size: 14px;
    color: #003F56;
    text-decoration: underline;
    cursor: pointer;
}


/* Todo 
different maximum height for desktop mode
*/
.SearchResult .body{ 
    max-height: calc(60vh - 140px); /* to allow scroll, this has to be specified in terms of viewport, 
    not the parent (as the parent has flexible height */
    overflow-y: auto; /* auto means the scroll only appears when there is overflow*/
    border: var(--debug-border);
    padding: 20px 30px 80px 30px; /*Extra padding at bottom to allow bottom to be visible with iPhone view port issue*/
    position: relative;
    border-bottom: var(--border-internal);
}


.SearchResult .item {
    /* border: var(--debug-border); */
    padding: 5px 7px 5px 7px;
    border: 1px solid var(--color-brand-primary);
    background-color: #FCFCFC;
    margin-bottom: 10px;
    border-radius: 4px;
    height: 60px;
    display: flex;
    flex-direction: row;  
    flex-wrap: nowrap;
    justify-content: space-between
}

.SearchResult .item:hover {
    background-color: #F6F8F8;
    cursor: pointer;
}
.SearchResult .item .left-column{
    max-width: calc(100% - 20px);
}
.SearchResult .item .right-column{
    padding: 3px 0px 0 0;
    width: 20px;
}

.SearchResult .item .train_name {
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px 7px 2px 7px;
}

.SearchResult .item .toc_name{
    font-family: 'Roboto Mono', monospace;
    color: #777777;
    font-weight: 300;
    font-size: 12px;
    padding: 2px 7px 2px 7px;
}



/*
* Footer
*/

.SearchResult .footer{
    height: 160px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    column-gap: 10px;
    padding: 15px 30px;
}

/*
* No train result
*/

.SearchResult .no-trains{
    position: absolute;
    text-align: center;
    font-size: 16px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
   
}



