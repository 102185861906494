.TfwKey{
    border: var(--debug-border);
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0

}


.TfwKey .body{
    border: var(--debug-border);
    padding: 25px 30px 30px 30px;
}


.TfwKey .row{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    height: 40px;
    width: 100%;
}

.TfwKey .train-icon-div{
    position: relative;
    height: 100%;
    width: 30px;
}

.TfwKey .train-icon{
    position: absolute;
}

.TfwKey .train-icon-description{
    height: 1px;
    line-height: 1px;
    padding-left: 9px;
    margin: 0;
}

.TfwKey .train-icon-description p{
    padding: 0;
    line-height: 0;
}

.TfwKey .direction-description{
    padding-top: 30px;
}


