



/*
* This should be specifed for modal Desktop
*/
.Modal{
    width: 100%; 
    height: 100%;
    overflow-x: hidden; /* overflow hidden to force border radius to propagate to child divs */
    overflow-y: auto;
    z-index: 100;
}

/*
* Mobile
*/
@media only screen and (max-width: 600px) {
    .Modal {
      /* background-color: rgb(201, 173, 230); */
      top: auto;
      bottom: 10px;
      left: 10px;
      right: 10px;
     
    }

}



.Menu h3{
    padding: 24px 24px 24px 33px;
    border: var(--debug-border)
}

.Menu .empty {
    flex-grow: 1;
}


.header{
    position: relative;
    height: 30px;
}

.close-button{
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 100;
}

.back-button{
    position: absolute;
    top: 5px;
    left: 20px;
    z-index: 100;
}



