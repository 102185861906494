.StopsDrawing {
    height: 100%;
    width: 100%
}

.StopsDrawing .green {
    fill: var(--color-rag-green);
}

.StopsDrawing svg{
    stroke: var(--color-stops-line);
    fill: var(--color-stops-end-dot) 
}

.StopsDrawing svg{
    stroke: var(--color-stops-line);
    fill: var(--color-stops-end-dot) 
}

.StopsDrawing #center-terminal{
    fill: var(--color-stops-end-dot-center) 
}

