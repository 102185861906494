.TfwLogo {
    position: relative;
    height: 56px;
    width: 70px;
}

.TfwLogo img {
    height: 40px; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%)
}

