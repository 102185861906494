.TrainlineLogo {
    position: relative;
    height: 56px;
    width: 56px;
}

.TrainlineLogo img {
    height: 25px; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%)
}

