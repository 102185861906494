.LanguageToggle{
    top: 12px;
    height: 40px;
    width: 110px;
    position: absolute;
    right: 10px;
    z-index: 10000;
    border-radius: var(--overlay-border-radius);
    background-color: var(--color-neutral);
    box-shadow: var(--overlay-box-shadow);
    border: var(--border-internal); 
    cursor: pointer;
}

.LanguageToggle:hover{
    background-color: rgb(234, 231, 231);
}

.LanguageToggle .language-text{
    position: absolute;
    font-size: 14px;
    color: var(--color-text-paragraph);
    font-family: var(--font-family-primary);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   
    

}


