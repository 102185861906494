
.SearchForm{
    position: relative;
    overflow: hidden;
}


.SearchForm .body{
    border: var(--debug-border);
    /* height: calc(100% - 160px) ; */
}

.footer{
    padding-top: 20px;
     height: 160px; 
     /* border: 2px solid purple; */
    /* border-top: var(--border-internal);
    box-shadow: var(--box-shadow-top); */
    /* overflow: hidden; */
}



.footer .reset{
    border: var(--debug-border);
    cursor: pointer;
    font-family: var(--font-family-secondary);
    color: var(--color-hyperlink);
    width: fit-content;
    margin: auto;
    text-decoration: underline; 
    padding: 10px;
    border-radius: var(--overlay-border-radius);
}

.footer .reset:hover {
    background-color: var(--color-tertiary);   
}

.SearchForm .form-container{
    margin: 10px 0 10px 0;

}


.SearchForm .bbox-input-container{
    display: flex; /* or inline-flex */
    flex-wrap: nowrap;
    justify-content: space-between; 
    flex-direction: row;
    width: 100%;
}

.SearchForm .use-viewport{
    border: var(--debug-border);
    font-family: var(--font-family-primary);
    color: var(--color-hyperlink);
    padding: 10px 0;
    font-size: 15px;
    font-weight: 400;
    text-decoration: underline; 
    cursor: pointer;
    padding: 10px;
}

.SearchForm .use-viewport:hover{
    background-color: var(--color-tertiary);
    border-radius: var(--overlay-border-radius); 
}

.SearchForm .geofence-input{
    /* border: solid #36ADB8 1.5px; */
    border: solid #CCCCCC 1.5px;
    height: 60px;
    width: 48%;
    padding: 8px;
    border-radius: 4px;  
    font-size: 16px;
    font-family: var(--font-family-primary);
    border-radius: var(--overlay-border-radius)
}


.SearchForm .geofence-input:focus{
    /* border: solid #36ADB8 1.5px; */
    border: solid var(--color-primary) 2px;
    outline: 0;
    
}
