.RecentreControl{
    height: 40px;
    width: 140px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10000;
    cursor: pointer;
    border-radius: var(--overlay-border-radius);
    background-color: var(--color-neutral);
    margin-top: 10px;
    box-shadow: var(--overlay-box-shadow);
    border: var(--border-internal);
    
}

.RecentreControl .row{

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.RecentreControl .icon{
    /* padding-right: 10px; */ 
    height: 23px;
    width: 23px;
}



.RecentreControl:hover {
    background-color: var(--color-tertiary);
}

.RecentreControl .label{
    font-family: var(--font-family-secondary);
    color: var(--color-hyperlink);
    font-weight: bold;
    font-size: 12px;
    padding: 0 6px;
    letter-spacing: 1px;
}
.RecentreControl svg{
    display: block;
    margin: auto;
    height: 100%;
    fill: var(--color-hyperlink)
}

.RecentreControl.disabled svg{
    fill: var(--color-tertiary)
}

.RecentreControl.disabled {
    color: var(--color-tertiary);
    cursor: auto
}

.RecentreControl.disabled .label{
    color: var(--color-tertiary);
}

/* .RecentreControl.disabled .label{
    color: var(--color-tertiary);
} */

.RecentreControl.disabled:hover {
    background-color: var(--color-neutral);
}