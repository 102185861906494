.SignalboxLogo {
    position: relative;
    height: 56px;
    width: 56px;
}

.SignalboxLogo img {
    height: 18px; 
    width: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%)
}


