.LnerTrainIcon {
    position: relative;
    font-size: 1px;
    line-height: 1px;
    opacity: 1;
    cursor:  pointer;
}


.LnerTrainIcon .green {
    stroke: var(--color-rag-green);
}

.LnerTrainIcon .amber {
    stroke: var(--color-rag-amber);
}

.LnerTrainIcon .red {
    stroke: var(--color-rag-red);
}

.LnerTrainIcon .label {
    width: 100%;
    color: var(--color-primary);
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    line-height: 1px;
    font-weight: 900;
    font-size: 16px;
}

.LnerTrainIcon.not-lner {
    opacity: 0.7;
}

.LnerTrainIcon.not-lner .green{
    stroke: grey;
}
.LnerTrainIcon.not-lner .red{
    stroke: grey;
}
.LnerTrainIcon.not-lner .amber{
    stroke: grey;
}



