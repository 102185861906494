.AvantiLogo {
    position: relative;
    height: 56px;
    width: 104px;
    border-right: var(--border-internal);
}

.AvantiLogo img {
    height: 40px; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%)
}


