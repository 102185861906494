
.Trains{
    overflow-y: fit-content;
    position: relative;
    padding-bottom: 20px;
}

.train-wrapper{
    margin-bottom: 10px;;
}





