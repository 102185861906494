

.FindMyTrain {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border: var(--debug-border);
    background-color: #F6F6F6;
}

/*
 Detect
*/
.FindMyTrain .center {
    text-align: center;
}

.FindMyTrain .detect-loader {
    position: relative;
    border: var(--debug-border);
    width: 100%;
    height: 270px;
}


.FindMyTrain .header-text{
    font-size: 12px;
    cursor: pointer;
}

.FindMyTrain .no-trains-wrapper{
    position: relative;
    height: 200px;
    width: 100%;
    border: var(--debug-border);
}

.FindMyTrain .no-trains-label{
    font-size: 22px;
    border: var(--debug-border);
    width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


  