.Stops {
    display: grid;
    grid-gap: 1px;
    grid-auto-rows:  minmax(70px, 70px); /** this is fixed don't change */
    grid-template-columns: 40px calc(100% - 120px) 80px;
    max-width: 340px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    white-space: nowrap;
    padding: 20px 0 20px 0;
}

.Stops .stop-name{
    font-weight: 700;
    font-size: 15px;
    color: var('--color-text-heading');
}

.Stops .platform{
    font-weight: 700;
    font-size: 13px;
}

.Stops .time{
    font-weight: 700;
    font-size: 13px;
}

.Stops .delayed{
    color: var(--color-rag-red);
}

.Stops .left-column {
    grid-column: 1;
    border: var(--debug-border);
}
.Stops .middle-column {
    grid-column: 2;
    padding-left: 10px;
    padding-right: 10px;
    border: var(--debug-border);
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.Stops .right-column {
    grid-column: 3 ;
    text-align: right;
    padding-right:5px;
    border: var(--debug-border);
}
