

.BetaTag {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1000;
}

.BetaTag .form-button{
    position: absolute;
    top:0;
    right: 19%;
    width: 112px;
    height: 35px;
    background-color: var(--color-primary); 
    cursor: pointer;
    pointer-events: auto;
    z-index: 100;
    border-bottom-right-radius: var(--overlay-border-radius);
    border-bottom-left-radius:var(--overlay-border-radius);
}

.BetaTag .form-button h2{
    color: var(--color-neutral);
    background-color: none;
    font-size: 16px;
    position: absolute;
    /* transform-origin: 0 0;
    transform: rotate(-90deg) translate(-50%, -50%);   */
    transform: translate(-50%, -50%);   
    top: 50%;
    left: 50%;
}

.BetaTag .form-button:hover {
    background-color: var(--color-primary-hover);    
}

.BetaTag .overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    bottom: 0%;
    right: 0%;
    background-color: rgba(84, 80, 80, 0.632);
    z-index: 10000;
    pointer-events: auto;
    overflow-y: scroll;
}

.BetaTag .content {
    position: absolute;
    top: 5%;
    left: 10%;
    bottom: 5%;
    right: 10%;
    background-color: var(--color-neutral);
    pointer-events: auto;
    border: var(--border-internal); 
    border-radius: var(--overlay-border-radius);
}

.BetaTag .header{
    display: flex;
    height: 56px;
    width: 100%;
    border-bottom: var(--border-internal);
    overflow: hidden; 
}

.BetaTag .body{
    height: calc(100% - 56px);
    overflow-y: scroll;
    padding: 20px;
}




