.Logo {

    border: var(--debug-border);
    position: relative;
}

.Logo .primary-logo-wrapper{
    position: relative;
    height: 40px;
    border: var(--debug-border);
}



