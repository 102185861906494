.RecentreButton{
    border-radius: var(--overlay-border-radius);  
    height: 30px;
    cursor: pointer;
    background-color: transparent;
}

.RecentreButton .row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

.RecentreButton .icon{
    /* padding-right: 10px; */ 
    height: 23px;
    width: 23px;
}



.RecentreButton:hover {
    background-color: var(--color-tertiary);
}

.RecentreButton .label{
    font-family: var(--font-family-secondary);
    color: var(--color-hyperlink);
    font-weight: bold;
    font-size: 12px;
    padding: 0 6px;
    letter-spacing: 1px;
}
.RecentreButton svg{
    display: block;
    margin: auto;
    height: 100%;
    fill: var(--color-hyperlink)
}

.RecentreButton.disabled svg{
    fill: var(--color-tertiary)
}

.RecentreButton.disabled {
    color: var(--color-tertiary);
    cursor: auto
}

.RecentreButton.disabled .label{
    color: var(--color-tertiary);
}

/* .RecentreButton.disabled .label{
    color: var(--color-tertiary);
} */

.RecentreButton.disabled:hover {
    background-color: transparent;
}