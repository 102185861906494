.AvantiTrainIcon {
    color: var(--bg-primary);
    position: relative;
    font-size: 1px;
    line-height: 1px;
    cursor: pointer;
}

.AvantiTrainIcon img {
    width: inherit;
    height: inherit;
}
