.SearchButton { 
    
    border-left: var(--border-internal);
 
}

/* .SearchButton .check {
    border: 4px solid var(--color-primary);
    border-radius: 3px;
    background-color: var(--color-primary);
    color: white;

} */

.SearchButton .applied svg{
    border-radius: 3px;
    background-color: var(--color-primary) !important; 
    fill: white !important; 
    stroke: white !important;
}







