
.AvantiKeyIcon{
    /* position: relative;
    font-size: 1px;
    line-height: 1px;
    padding-right: 20px; */

}

.AvantiKeyIcon img{
    position: absolute;
    top: 50%;
    left: 50%;
}


