.LnerLogo {
    position: relative;
    padding: 5px 10px;
    height: 90%;;
}

.LnerLogo img {
    height: 100%;
}


